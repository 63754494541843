<template>
  <div class="step-one-wrapper">
    <div class="fs-lg font-weight-bolder text-uppercase my-3">{{ $t('label.when_is_trip') }}?</div>

    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
      <FloatingDatetimePickerFormGroup
        class="mb-0 w-md-50 w-100"
        :value="form.income_date"
        :min-datetime="computedMinIncomeDate"
        :max-datetime="computedMaxIncomeDate"
        :disabled-dates="incomeDisabledDates"
        :attributes="incomeAttributes"
        :placeholder="$t('placeholder.from')"
        :with-icon="false"
        :allow-clear="true"
        input-rounded-size="lg"
        popover-visibility="click"
        :blur="triggerIncomeBlur"
        @focus="onIncomeFocus"
        @change="form.income_date = $event"
      />
      <div class="font-weight-boldest h3 mx-3 my-2 my-md-0 mb-0">—</div>
      <FloatingDatetimePickerFormGroup
        class="mb-0 w-md-50 w-100"
        :value="form.exit_date"
        :min-datetime="computedMinExitDate"
        :max-datetime="computedMaxExitDate"
        :disabled-dates="exitDisabledDates"
        :attributes="exitAttributes"
        :placeholder="$t('placeholder.to')"
        :with-icon="false"
        :allow-clear="true"
        input-rounded-size="lg"
        popover-visibility="click"
        :blur="triggerExitBlur"
        @focus="onExitFocus"
        @change="form.exit_date = $event"
      />
    </div>

    <div class="fs-lg font-weight-bolder text-uppercase my-3">{{ $t('label.who_is_coming') }}?</div>

    <b-row class="align-items-center mb-5">
      <b-col cols="7" md="9">
        <div class="fs-md">{{ $t('label.adults') }}</div>
        <div class="fs-sm">{{ $t('label.adult_age') }}</div>
      </b-col>
      <b-col cols="5" md="3">
        <touchspin-form-group
          class="touchspin"
          :with-label="false"
          :value="form.guests.adults_count"
          :min-value="1"
          :max-value="6"
          @change="form.guests.adults_count = $event"
        />
      </b-col>
    </b-row>

    <b-row class="align-items-center">
      <b-col cols="7" md="9">
        <div class="fs-md">{{ $t('label.children') }}</div>
        <div class="fs-sm">{{ $t('label.child_age') }}</div>
      </b-col>
      <b-col cols="5" md="3">
        <touchspin-form-group
          class="touchspin"
          :with-label="false"
          :value="form.guests.children_count"
          :min-value="0"
          :max-value="4"
          @change="form.guests.children_count = $event"
        />
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col v-for="(_, idx) in childrenCount" :key="idx" cols="6" lg="3" class="m-0 px-1">
        <floating-multiselect-form-group
          class="custom-multiselect-wrapper mb-5"
          :class="form.guests.children_age[idx] ? 'filled' : 'empty'"
          :value="ageOptions.find(el => el.id === form.guests.children_age[idx])"
          :placeholder="$t('label.age_needed')"
          :options="ageOptions"
          input-rounded-size="lg"
          size="sm"
          :searchable="false"
          @select="onChildAgeChange($event, idx)"
        >
          <template v-slot:singleLabel="{ option }">
            <div class="fs-sm d-flex align-items-center">
              {{ `${option.label} ${$t('label.years_old')}` }}
            </div>
          </template>
        </floating-multiselect-form-group>
      </b-col>
    </b-row>

    <button
      class="btn gb-btn btn-green fs-xl font-weight-bold text-uppercase w-100 mb-5 mt-7 px-9 py-4"
      :disabled="!formIsValid"
      @click="onNext"
    >
      {{ $t('btn.next') }}
    </button>
  </div>
</template>

<script>
import FloatingDatetimePickerFormGroup from '../../../components/elements/form-groups/FloatingDatetimePickerFormGroup.vue';
import TouchspinFormGroup from '../../../components/elements/form-groups/TouchspinFormGroup.vue';
import FloatingMultiselectFormGroup from "@/components/elements/form-groups/FloatingMultiselectFormGroup.vue";
import DatetimePickerFormGroup from "@/components/elements/form-groups/DatetimePickerFormGroup.vue";

export default {
  components: {
    DatetimePickerFormGroup,
    FloatingMultiselectFormGroup,
    FloatingDatetimePickerFormGroup,
    TouchspinFormGroup
  },
  props: {
    payload: {type: Object, default: () => {}},
    valid: {type: Boolean, default: false},
    clearDates: {type: Boolean, default: false},
	  loadBookings: {type: Boolean, default: false}
  },
  data: () => ({
    formSubmitted: false,
    form: {
      income_date: null,
      exit_date: null,
      guests: {
        adults_count: 1,
        children_count: 0,
        children_age: [],
      }
    },
    error: {
      income_date: null,
      exit_date: null,
    },
    incomeDisabledDates: [],
    exitDisabledDates: [],
    triggerIncomeBlur: false,
    triggerExitBlur: false,
  }),
  computed: {
    incomeAttributes() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return this.incomeDisabledDates.map(b => {
        const attribute = this.createAttributeForDates(b.start, b.end);

        attribute.dates = attribute.dates.filter(date => {
          const dateToCheck = new Date(date);
          dateToCheck.setHours(0, 0, 0, 0);
          return dateToCheck >= today;
        });

        return attribute;
      }).filter(attribute => {
        return attribute.dates.length > 0;
      });
    },
    exitAttributes() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      let attributes = this.bookings.map(b => this.createAttributeForDates(b.start, b.end));

      if (!this.form.income_date) {
        return attributes.map(attr => {
          attr.dates = attr.dates.filter(date => {
            const dateToCheck = new Date(date);
            dateToCheck.setHours(0, 0, 0, 0);
            return dateToCheck >= today;
          });
          return attr;
        }).filter(attr => attr.dates.length > 0);
      }

      attributes = attributes.map(attr => {
        const filteredDates = attr.dates.filter(date => {
          const dateToCheck = new Date(date);
          dateToCheck.setHours(0, 0, 0, 0);
          return dateToCheck >= today &&
            this.formatDateToString(date) !== this.formatDateToString(this.computedMaxExitDate);
        });
        return { ...attr, dates: filteredDates };
      });

      return attributes;
    },
    bookings() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return this.$store.state.guestBookingStore.bookings
        .filter(b => new Date(b.exitDate) >= today)
        .map(b => {
          const exitDate = new Date(b.exitDate);
          return { start: new Date(b.incomeDate), end: new Date(exitDate.setDate(exitDate.getDate() - 1)) };
        });
    },
    computedMinIncomeDate() {
      if (!this.form.exit_date) {
        let now = new Date();
        now.setHours(14, 0, 0, 0);

        let minDate = new Date(now);
        if (new Date().getHours() >= 14) {
          minDate.setDate(minDate.getDate() + 1);
        }

        return minDate < now ? now : minDate;
      }
      const bookings = [...this.bookings].reverse();
      let minAllowedDate = bookings.find(el => {
        if (new Date(el.end) < new Date(this.form.exit_date) && new Date(el.end) >= new Date()) {
          return el;
        }
      });

      return minAllowedDate ? new Date(minAllowedDate.end) : new Date();
    },
    computedMaxIncomeDate() {
      let exitDate = new Date(this.form.exit_date);
      return this.form.exit_date ? exitDate.setDate(exitDate.getDate() - 1) : null;
    },
    computedMinExitDate() {
      let incomeDate = new Date(this.form.income_date);
      return this.form.income_date ? incomeDate.setDate(incomeDate.getDate() + 1) : this.computedMinIncomeDate;
    },
    computedMaxExitDate() {
      if (!this.bookings.length || !this.form.income_date) return null;

      const selectedStartDate = new Date(this.form.income_date);
      const bookingsAfterSelectedDate = this.bookings.filter(booking => new Date(booking.start) > selectedStartDate);
      const sortedBookings = bookingsAfterSelectedDate.sort((a, b) => new Date(a.start) - new Date(b.start));

      if (sortedBookings.length === 0) return null;

      const closestBooking = sortedBookings[0];

      return new Date(closestBooking.start);
    },
    childrenCount() {
      return this.form.guests.children_count;
    },
    ageOptions() {
      let options = [];
      for (let i = 0; i <= 12; i++) {
        options.push({id: i.toString(), label: i.toString()});
      }

      return options;
    },
    formIsValid() {
      const bookingDatesValid = this.form.income_date && this.form.exit_date;
      const childrenValid = this.form.guests.children_count === this.form.guests.children_age.length;

      return bookingDatesValid && childrenValid;
    }
  },
  watch: {
    valid: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getBookings();
        }
      }
    },
    bookings: {
      handler(data) {
        this.incomeDisabledDates = data;
        this.exitDisabledDates = data;
      },
      deep: true
    },
    computedMaxExitDate(date) {
      this.exitDisabledDates = this.incomeDisabledDates.filter(el => el.start === date);
    },
    childrenCount(val) {
      if (val < this.form.guests.children_age.length) {
        this.form.guests.children_age.splice(val);
      }
    },
    form: {
      immediate: true,
      handler(data) {
        this.$emit('formChange', data);
      },
      deep: true
    },
    clearDates(value) {
      if (value) {
        this.form.income_date = null;
        this.form.exit_date = null;
      }
    },
	  loadBookings(val) {
			if (val) {
				this.getBookings();
			}
	  },
    triggerIncomeBlur(val) {
      if (val) {
        setTimeout(() => this.triggerIncomeBlur = false, 500);
      }
    },
    triggerExitBlur(val) {
      if (val) {
        setTimeout(() => this.triggerExitBlur = false, 500);
      }
    }
  },
  methods: {
    getBookings() {
      this.$store.dispatch('guestBookingStore/GET_BOOKINGS', this.payload);
    },
    onNext() {
      this.$emit('next');
    },
    onIncomeFocus() {
      this.triggerExitBlur = true;
    },
    onExitFocus() {
      this.triggerIncomeBlur = true;
    },
    onChildAgeChange(e, idx) {
      this.$set(this.form.guests.children_age, idx, e.id);
    },
    createAttributeForDates(start, end) {
      return {
        highlight: { contentClass: 'text-danger opacity-30' },
        dates: this.getDatesArray(start, end),
      };
    },
    getDatesArray(start, end) {
      start = new Date(start);
      end = new Date(end);
      end.setDate(end.getDate() + 1);

      const datesArray = [];
      let currentDate = start;

      while (currentDate <= end) {
        datesArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return datesArray;
    },
    formatDateToString(date) {
      let d = new Date(date);
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
  }
}
</script>

<style lang="scss">
.step-one-wrapper {
	.active {
		font-size: 14px !important;
	}
}

.custom-multiselect-wrapper {
  &.filled {
    .app-multiselect {
      .multiselect__tags {
        border-color: #6E7173 !important;

        .multiselect__single {
          color: #222;
        }
      }
    }
  }

	label {
		top: 8px !important;
		max-width: calc(100% - 30px);
		font-size: 14px !important;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (max-width: 767px) {
  .step-one-wrapper {
    .active {
      font-size: 12px !important;
    }

    .daterange-input-label {
      &.filled {
        top: 10px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.gb-datepicker::v-deep {
  margin-bottom: 0;

  label.filled {
    color: #222 !important;
  }

  input.filled {
    color: #222 !important;
    border-color: #6E7173 !important;
  }

  .vc-container {
    --gray-600: #222;
  }

  .vc-popover-content-wrapper {
    .vc-popover-content {
      .vc-pane-container {
	      .vc-header {
		      .vc-title {
			      font-size: 16px;
		      }
	      }

	      .vc-weeks {
		      .vc-weekday {
			      font-size: 10px;
		      }
	      }

        .vc-day:has(>.vc-highlights>.vc-day-layer.vc-day-box-left-center) .vc-day-content {
          color: white;
        }

        .vc-day {
          &.is-not-in-month {
            opacity: 0 !important;
          }

	        .vc-day-content {
		        font-size: 14px;
	        }

          .vc-highlights.vc-day-layer {
            .vc-day-layer.vc-day-box-center-center {
              .vc-highlight {
                border-color: #222 !important;

                &.vc-highlight-base-middle {
                  background-color: #F7F7F7 !important;
                }
              }
            }

            .vc-day-layer.vc-day-box-left-center {
              .vc-highlight {
                border-color: #222 !important;

                &.vc-highlight-base-end {
                  background-color: #F7F7F7 !important;
                }
              }
            }

            .vc-day-layer.vc-day-box-right-center {
              .vc-highlight {
                border-color: #222 !important;

                &.vc-highlight-base-start {
                  background-color: #F7F7F7 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.touchspin::v-deep {
  margin-bottom: 0;

  .btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    border-radius: 50%;
  }
}

.filled::v-deep {
  label {
    display: none;
  }
}

.empty::v-deep {
  .multiselect__tags {
    border-color: #f64e60;
  }

  label {
    color: #f64e60;
  }
}
</style>
