<template>
  <div class="step-two-wrapper">
    <div class="fs-lg font-weight-bolder text-uppercase mt-3 mb-1">{{ $t('label.who_is_your_guest') }}?</div>
    <div class="fs-md mb-4">{{ $t('description.enter_personal_details_for_gb') }}?</div>

    <b-row>
      <b-col lg="6">
        <floating-input-form-group
          class="custom-input-wrapper mb-4"
          :value="form.fName"
          :placeholder="$t('label.name')"
          :with-error="error.fName"
          input-rounded-size="lg"
          @input="form.fName = $event"
        />
      </b-col>
      <b-col lg="6">
        <floating-input-form-group
          class="custom-input-wrapper mb-4"
          :value="form.lName"
          :placeholder="$t('label.last_name')"
          :with-error="error.lName"
          input-rounded-size="lg"
          @input="form.lName = $event"
        />
      </b-col>
      <b-col lg="6">
        <floating-input-form-group
          class="custom-input-wrapper mb-4"
          :value="form.personal_id"
          :placeholder="$t('label.passport_number')"
          :with-error="error.personal_id"
          input-rounded-size="lg"
          @input="form.personal_id = $event"
        />
      </b-col>
      <b-col lg="6">
        <floating-multiselect-form-group
          class="custom-select-wrapper mb-4"
          :value="genders.find(el => el.id === form.gender_id)"
          :placeholder="$t('label.gender')"
          :options="genders"
          input-rounded-size="lg"
          :searchable="false"
          @select="form.gender_id = $event.id"
        />
      </b-col>
      <b-col lg="6">
        <floating-multiselect-form-group
          class="country-input-wrapper mb-4 mb-lg-0"
          :value="countries.find(el => el.id === form.citizen)"
          :placeholder="$t('label.citizenship')"
          :options="countries"
          input-rounded-size="lg"
          @select="form.citizen = $event.id"
        >
          <template v-slot:singleLabel="{ option }">
            <div class="d-flex align-items-center">
              <div class="flag-wrapper">
                <inline-svg :src="`/media/svg/flags/${ option.slug }.svg`"/>
              </div>
              <div class="ml-2">{{ option.label }}</div>
            </div>
          </template>
        </floating-multiselect-form-group>
      </b-col>
      <b-col lg="6">
        <floating-input-form-group
          class="phone-number-input-wrapper"
          :value="form.number"
          :placeholder="$t('label.phone_number')"
          input-type="number"
          :with-error="error.number"
          input-rounded-size="lg"
          @input="form.number = $event"
        >
          <template #prefix>
            <div class="d-flex flex-column justify-content-center align-items-center phone-number-prefix h-100">
              <floating-multiselect-form-group
                class="phone-code-select-wrapper"
                :value="phoneCodes.find(el => el.code === phoneNumberCode)"
                :options="phoneCodes"
                input-rounded-size="lg"
                @select="phoneNumberCode = $event.code"
              >
                <template v-slot:singleLabel="{ option }">
                  <div class="flag-wrapper">
                    <inline-svg :src="`/media/svg/flags/${ option.slug }.svg`"/>
                  </div>
                  <div class="fs-sm mt-2">{{ option.code }}</div>
                </template>
              </floating-multiselect-form-group>
            </div>
          </template>
        </floating-input-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-10 mb-5">
      <b-col cols="4" lg="4">
        <button
          class="btn btn-outline-dark btn-hover-light-dark fs-xl font-weight-bold text-uppercase w-100 py-4"
          @click="onBack"
        >
          {{ $t('btn.back') }}
        </button>
      </b-col>
      <b-col cols="8" lg="8">
        <button
          class="btn btn-green fs-xl font-weight-bold text-uppercase w-100 py-4"
          :disabled="!formIsValid"
          @click="onNext"
        >
          {{ $t('btn.next') }}
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {formValidation} from '@/mixins/formValidation';
import InputFormGroup from '../../../components/elements/form-groups/InputFormGroup.vue';
import FloatingInputFormGroup from '@/components/elements/form-groups/FloatingInputFormGroup.vue';
import FloatingMultiselectFormGroup from '@/components/elements/form-groups/FloatingMultiselectFormGroup.vue';
import MultiselectFormGroup from '../../../components/elements/form-groups/MultiselectFormGroup.vue';

export default {
  components: {
    InputFormGroup,
    FloatingInputFormGroup,
    FloatingMultiselectFormGroup,
    MultiselectFormGroup
  },
  mixins: [formValidation],
  props: {
    valid: {type: Boolean, default: false},
  },
  data: () => ({
    phoneNumberCode: '+995',    // Georgia
    form: {
      fName: '',
      lName: '',
      personal_id: '',
      gender_id: '',
      citizen: '',
      country: '-',
      address: '-',
      email: '-',
      number: ''
    },
    error: {
      fName: false,
      lName: false,
      personal_id: false,
      gender_id: false,
      citizen: false,
      number: false
    },
  }),
  computed: {
    countries() {
      return this.$store.state.bookingsStore.countries.filter(c => c.code !== '').map(c => {
        return {id: c.code.toLowerCase(), label: c.EnglishName, slug: c.countrySlug};
      });
    },
    phoneCodes() {
      return this.$store.state.bookingsStore.countries.filter(c => c.code !== '' && c.phoneCode !== '').map(c => {
        return {
          id: c.phoneCode + ' ' + c.EnglishName,
          label: c.phoneCode + ' - ' + c.EnglishName,
          code: c.phoneCode,
          slug: c.countrySlug
        };
      });
    },
    genders() {
      return [
        {id: 0, label: this.$t('label.male')},
        {id: 1, label: this.$t('label.female')},
      ];
    },
    selectedCitizenship() {
      return this.form.citizen ? this.countries.find(c => c.id === this.form.citizen) : {};
    },
    formIsValid() {
      const fieldsFilled = !Object.values(this.form).some(value => String(value).trim() === '');
      const phoneNumberValid = this.validatePhoneNumber(this.form.number);

      return fieldsFilled && phoneNumberValid;
    }
  },
  watch: {
    valid: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getCountries();
        }
      }
    },
    form: {
      immediate: true,
      handler(data) {
        const form = {...this.form, number: this.phoneNumberCode + data.number};
        this.$emit('formChange', form);
      },
      deep: true
    },
    selectedCitizenship(value) {
      if (value) {
        const phone = this.phoneCodes.find(el => el.slug === value.slug);
        this.phoneNumberCode = phone?.code;
      }
    },
    phoneNumberCode(value) {
      const form = {...this.form, number: value + this.form.number};
      this.$emit('formChange', form);
    },
  },
  methods: {
    getCountries() {
      this.$store.dispatch('bookingsStore/GET_COUNTRIES');
    },
    onBack() {
      this.$emit('back');
    },
    onNext() {
      this.$emit('next');
    },
  }
}
</script>

<style lang="scss">
.step-two-wrapper {
	.active {
		font-size: 14px !important;
	}
}

@media (max-width: 767px) {
  .step-two-wrapper {
    .custom-input-wrapper, .phone-number-input-wrapper {
      label.active {
        top: 10px;
      }
    }

    .active {
      font-size: 12px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.custom-input-wrapper::v-deep,
.custom-select-wrapper::v-deep,
.country-input-wrapper::v-deep,
.phone-code-select-wrapper::v-deep,
.phone-number-input-wrapper::v-deep {
  .valid {
    border-color: #6E7173 !important;
    color: #222 !important;
  }

  label {
    &.text-green {
      color: #222 !important;
    }
  }

  .app-multiselect {
    .multiselect__tags {
      border-color: #6E7173 !important;

      .multiselect__single {
        color: #222;
      }
    }
  }
}

.phone-number-input-wrapper::v-deep {
  label {
    left: 80px;
  }

  input {
    padding-left: 75px;
  }
}

.country-input-wrapper::v-deep {
  .flag-wrapper {
    height: 20px;
    width: 20px;
    background-color: #F1F3F4;

    svg {
      width: 20px;
    }
  }
}

.phone-code-select-wrapper::v-deep {
  width: 60px;

  .multiselect__content-wrapper {
    width: 250px;
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__tags {
    padding: 0;

    .multiselect__single {
      height: 58px;
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      text-align: center;
    }
  }
}

.phone-number-prefix {
  width: 60px;

  .flag-wrapper {
    height: 20px;
    width: 20px;
    background-color: #F1F3F4;

    svg {
      width: 20px;
    }
  }
}

@media (max-width: 767px) {
  .phone-number-input-wrapper::v-deep {
    label {
      left: 75px;
    }

    input {
      padding-left: 70px;
    }

    .phone-number-prefix {
      svg {
        width: 15px;
        margin-top: 5px;
      }
    }
  }

  .phone-code-select-wrapper::v-deep {
    width: 60px;

    .multiselect__tags {
      .multiselect__single {
        height: 43px;
        font-size: 10px;
      }
    }
  }
}
</style>

<!--
missed flags:
- antarctica
- cyprus
- faroeislands
- guyana
- kuwait
- mayotte
- netherlandsantilles
- newcaledonia
- saintlucia
-->