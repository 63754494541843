export const dateMixin = {
  methods: {
    dateToString(date) {
      const currentDate = new Date(date);
      
      let day = currentDate.getDate();
      let month = currentDate.getMonth() + 1;
      let year = currentDate.getFullYear();
      
      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;
      
      return day + '.' + month + '.' + year;
    }
  }
}