<template>
  <div class="fv-row position-relative">
    <b-form-textarea
      :class="{error: withError && !value, valid: !!value, 'rounded-xxl': inputRoundedSize === 'lg'}"
      :value="value"
      :disabled="disabled"
      :rows="rows"
      :max-rows="maxRows"
      @keydown="onKeyDown"
      @focus="inputActive = true"
      @blur="inputActive = false"
      @input="$emit('input', $event)"
    />

    <label :class="{ 'active': inputActive || !!value, 'error': error, 'text-green': !!value && !error }">
      {{ placeholder }}
    </label>
    <div v-if="!!error" class="text-danger mt-1">
      <inline-svg src="/media/svg/icons/General/Attention.svg"/>
      {{ $t(error) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    withError: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    helpMessage: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    },
    rows: {
      type: Number,
      default: 2
    },
    maxRows: {
      type: Number,
      default: 5
    },
    inputRoundedSize: {
      type: String,
      default: 'sm'
    }
  },
  data: () => ({
    inputActive: false
  }),
  computed: {
    textAreaRows() {
      const lines = this.value.split('\n');
      return Math.max(lines.length, 2);
    }
  },
  methods: {
    onKeyDown(evt) {
      if (this.maxLength && this.value.length >= this.maxLength) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          evt.preventDefault()
          return;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  min-height: 60px;
  padding: 15px 16px 0;
  font-size: 16px;
  color: #6E7173 !important;
  background-color: #FFF !important;
  border: 1px solid #6E7173 !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    margin: 25px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6B6B6B;
    border-radius: 10px;
  }

  &.error {
    border-color: #E90900 !important;
  }

  &.valid {
    border-color: #008E49 !important;
  }
}

label {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  font-size: 16px;
  color: #999;
  background: #FFF;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;

  &.active {
    transform: translate(-3px, -14px);
    font-size: 12px;
    color: #333;
  }

  &.error {
    color: #E90900;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  input {
    height: 45px;
    font-size: 14px;
  }

  label {
    top: 12px;
    font-size: 14px;

    &.active {
      transform: translate(-3px, -7px);
      font-size: 11px;
    }
  }
}
</style>