<template>
  <b-row class="justify-content-center m-0">
    <b-col md="6" xl="5">
      <b-card v-if="!requestSent" class="card-custom user-select-none my-10">
        <template #default>
          <div class="text-left text-md-center fs-xxxl font-weight-bolder">ORBI CITY</div>
          <div class="gbtimer position-absolute d-flex align-items-center font-weight-bold">
	          <inline-svg src="/media/svg/icons/Custom/timer.svg" />
	          <span class="fs-xl text-danger ml-1">{{ formattedTime }}</span>
            <span
              class="d-block d-md-none far fa-question-circle font-size-h3 ml-2"
              v-b-modal:timeIsTickingModal
            />
            <span
              class="d-none d-md-block far fa-question-circle font-size-h3 ml-2"
              v-b-tooltip.righttop.hover.html
              :title="$t('description.guest_booking_time_is_ticking')"
            />
          </div>
          <div class="text-left text-md-center fs-xxl font-weight-bolder text-uppercase mt-3">
            {{ $t('title.every_stay_story') }}
          </div>
          <step-one
            v-show="currentPageIdx === 1"
            :payload="payload"
            :valid="validPayload"
            :clear-dates="clearDates"
            :load-bookings="loadBookings"
            @formChange="onFormChange"
            @next="currentPageIdx++"
          />
          <step-two
            v-show="currentPageIdx === 2"
            :valid="validPayload"
            @formChange="onFormChange"
            @next="currentPageIdx++"
            @back="currentPageIdx--"
          />
          <step-three
            v-show="currentPageIdx === 3"
            :data="form"
            :valid="validPayload"
            @formChange="onFormChange"
            @submit="submit"
            @back="currentPageIdx--"
          />
        </template>
      </b-card>

      <b-card v-if="requestSent">
        <template #default>
          <div class="text-center fs-xxxl font-weight-bolder">ORBI CITY</div>
          <div class="text-center fs-xxl font-weight-bolder text-uppercase mt-3">
            {{ $t('description.gb_request_created') }}
          </div>
          <div class="text-center fs-lg font-weight-bolder text-uppercase mt-2">
            {{ $t('description.gb_request_created_desc') }}
          </div>
          <button class="btn btn-green fs-xl text-uppercase w-100 mb-5 mt-10 px-9 py-4" @click="onOk">
            {{ $t('btn.ok') }}
          </button>
        </template>
      </b-card>
    </b-col>

    <b-modal id="timeIsTickingModal" size="sm" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <template #default>
        <div class="fs-md text-center" v-html="$t('description.guest_booking_time_is_ticking')"/>
        <button
          class="btn btn-hover-green fs-xl font-weight-bold text-uppercase w-100 mt-5 px-9 py-4"
          @click="$bvModal.hide('timeIsTickingModal')"
        >
          {{ $t('btn.ok') }}
        </button>
      </template>
    </b-modal>

    <b-modal id="timeIsUpModal" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <template #default>
        <div class="fs-xxxl text-center font-weight-bolder mb-3">{{ $t('label.oops') }}!</div>
        <div class="fs-xxl text-center">{{ $t('description.guest_booking_time_is_up') }}</div>
        <button
          class="btn btn-hover-green fs-xl font-weight-bold text-uppercase w-100 mt-10 px-9 py-4"
          @click="onUnderstand"
        >
          {{ $t('btn.understand') }}
        </button>
      </template>
    </b-modal>

    <b-modal id="notAvailableDatesModal" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <template #default>
        <div class="fs-xxxl text-center font-weight-bolder mb-3">{{ $t('label.oops') }}!</div>
        <div class="fs-xxl text-center">{{ $t('description.someone_booked_dates_you_selected') }}</div>
        <button
          class="btn btn-hover-green fs-xl font-weight-bold text-uppercase w-100 mt-10 px-9 py-4"
          @click="goToFirstStep"
        >
          {{ $t('btn.understand') }}
        </button>
      </template>
    </b-modal>

	  <b-modal id="alreadyBookedModal" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <template #default>
        <div class="fs-xxxl text-center font-weight-bolder mb-3">{{ $t('label.oops') }}!</div>
        <div class="fs-xxl text-center">{{ $t('description.already_qr_booked') }}</div>
        <button
          class="btn btn-hover-green fs-xl font-weight-bold text-uppercase w-100 mt-10 px-9 py-4"
          @click="onOk"
        >
          {{ $t('btn.understand') }}
        </button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import StepOne from './request-components/StepOne.vue';
import StepTwo from './request-components/StepTwo.vue';
import StepThree from './request-components/StepThree.vue';

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree
  },
  data: () => ({
    currentPageIdx: 1,
    timer: null,
    form: {},
    validPayload: false,
    clearDates: false,
    requestSent: false,
	  loadBookings: false,
  }),
  async mounted() {
    await this.getTimeDetails();
    this.startTimer();
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timer / 60);
      const seconds = this.timer % 60;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    apartmentId() {
      return this.$route.params.apartmentId;
    },
    hash() {
      return this.$route.params.hash;
    },
    timeHash() {
      return this.$route.params.timeHash;
    },
    payload() {
      return {
        apartmentId: this.apartmentId,
        hash: this.hash,
        timeHash: this.timeHash,
      };
    }
  },
  watch: {
    timer(val) {
      if (val > 0) {
        this.validPayload = true;
      } else if (val === 0) {
        this.$nextTick(() => this.$bvModal.show('timeIsUpModal'));
      }
    },
    clearDates(value) {
      if (value) {
        setTimeout(() => this.clearDates = false, 1000);
      }
    },
	  loadBookings(val) {
			if (val) {
				setTimeout(() => this.loadBookings = false, 1000);
			}
	  }
  },
  methods: {
    startTimer() {
      const interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    async getTimeDetails() {
      await this.$store.dispatch('guestBookingStore/GET_TIME_DETAILS', this.payload)
        .then(res => {
          this.timer = res.minutes * 60 + res.seconds;
        })
        .catch(error => {
	        if (error?.response?.status === 403) {
		        this.$bvModal.show('alreadyBookedModal');
	        } else {
		        this.timer = 0;
	        }
        })
    },
    async submit() {
      this.$store.dispatch('guestBookingStore/BOOK', {
        apartmentId: this.apartmentId,
        hash: this.hash,
        timeHash: this.timeHash,
        form: this.form
      })
        .then(res => {
          console.log('guestBookingStore/BOOK response');
          console.log(res);

          this.requestSent = true;
        })
        .catch(err => {
          console.log('guestBookingStore/BOOK error');
          console.log({err});

          if (err?.response?.status === 409) {
						this.loadBookings = true;
            this.$bvModal.show('notAvailableDatesModal');
          }
        });
    },
    onFormChange(data) {
      this.form = {...this.form, ...data};
    },
    onUnderstand() {
      this.$router.push({name: 'initGuestBooking', params: {apartmentId: this.apartmentId, hash: this.hash}});
    },
    onOk() {
      window.location.href = 'https://orbihotels.com/';
    },
    goToFirstStep() {
      this.clearDates = true;
      this.currentPageIdx = 1;
      this.$bvModal.hide('notAvailableDatesModal');
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip::v-deep {
	.tooltip-inner {
		font-size: 16px !important;
	}
}

.card-custom {
  border-radius: 20px;
}

.gbtimer {
  top: 29px;
  right: 30px;
}
</style>

<style lang="scss">
#timeIsUpModal, #childAge, #notAvailableDatesModal {
  padding-right: 0 !important;
}

.gb-btn {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>